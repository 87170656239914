import React, { useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, navigate } from "gatsby";
import { useLocation } from "@reach/router";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PageHero from "../components/PageHero";
import HomeCta from "../components/HomeCta";

// eslint-disable-next-line
export const CustomPageTemplate = ({
  content,
  contentComponent,
  description,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const location = useLocation();

  useLayoutEffect(() => {
    if (
      location.pathname === "/documents/policy-wording/" &&
      typeof window !== "undefined"
    ) {
      navigate("/static/policy-wording");
    }
  }, []);

  return (
    <section className="section custom-page">
      {helmet || ""}
      <div className="container content container-page">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <p>{description}</p>
            <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
  );
};

const PolicyContextSection = ({
  content,
  contentComponent,
  description,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const location = useLocation();

  useLayoutEffect(() => {
    if (
      location.pathname === "/documents/policy-wording/" &&
      typeof window !== "undefined"
    ) {
      navigate("/static/policy-wording");
    }
  }, []);

  return (
    <section className="section custom-page">
      {helmet || ""}
      <div className="container content container-page">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <p>{description}</p>
            <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
  );
};

const CustomPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout noPadding darkLogo>
      <PageHero title={post.frontmatter.title} />
      <CustomPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
      <HomeCta />
    </Layout>
  );
};

CustomPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CustomPage;

export const pageQuery = graphql`
  query CustomPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
